import React from "react";
import "./emblemDefinition.css";
import logo from '../../../assets/emblemdarktransparent.png';
import emblems from '../../../assets/emblems.png';


const EmblemDefintion = () => {
  return (
    <div className="section-container">
      <div className="section1">
        <div className="section-image1">
          <img src={logo} alt="Emblem Logo" />
        </div>
        <div className="section-text">
          <h1>What are emblems?</h1>
        </div>
      </div>

      <div className="section2">
        <div className="section-image2">
          <img src={emblems} alt="Emblems" />
        </div>
        <div className="section-text">
          <h2>Emblems are representative collectible digital art souvenirs made by companies or institutions for places, events and experiences.</h2>
        </div>
      </div>

      {/* Add more sections as needed */}
    </div>
  );
};

export default EmblemDefintion;
