import React from 'react';
import './Footer.css';
import instagram from '../../../assets/instagram.png';
import linkedIn from '../../../assets/linkedin.png';
import logo from '../../../assets/emblemWhiteLogo.png';



function Footer() {
  return (
    <div className='footer'>
      <div className='sb_footer section padding'>

        <div className='sb_footer-links'>

          
        <div className='sb_footer-links-div'>
    <div className='logo'>
        <p><img src={logo} alt="" /></p>
    </div>
    <div className='socialmedia'>
        <a href="https://instagram.com/emblem_app?igshid=NGVhN2U2NjQ0Yg==" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="Instagram" />
        </a>
        <a href="https://www.linkedin.com/company/emblemlu/" target="_blank" rel="noopener noreferrer">
            <img src={linkedIn} alt="LinkedIn" />
        </a>
    </div>
</div>

          <div className='sb_footer-bellow'>
            <div className='sb_footer-copyright'>
              <p>
                @{new Date().getFullYear()} Emblem. All right reserved.
              </p>

            </div>
            <div className='sb_footer-bellow-links'>
              <a href="/terms">
                <p>Terms and conditions</p>
              </a>
              <a href="/privacy">
                <p>Privacy</p>
              </a>
              
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
export default Footer;

