import React from 'react';
import './termConditions.css'; // Import your CSS file for styling

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <h1>Terms and Conditions for Emblem</h1>
            <p>Last Updated: 11th December, 2023</p>

            <h2>Welcome to Emblem!</h2>
            <p>
                These Terms and Conditions govern your access to and use of the Emblem application and its services.
                By accessing or using Emblem, you agree to be bound by these terms. These Terms of Service (the “Terms”) constitute
                a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Emblem
                and its affiliated companies ("Company," “we,” “us,” or “our”), concerning your access to and use of the website
                www.emblem.lu (the “Website”), the Company App (the “App”), as well as any other media form, media channel,
                mobile website, or mobile application related, linked, or otherwise connected thereto (collectively, the
                “Services”).
            </p>

            <h2>Your access and use of our Services</h2>
            <p>
                Your access and use of our Services is subject to the Terms and all applicable laws. By accessing or using any part of
                the Services, you accept, without limitation or qualification, these Terms. If you do not agree with all of these
                Terms, you may not use any portion of the Services, including any portion of the Service and App. If an individual is
                accessing the Service on behalf of a business entity, by doing so, such individual represents that they have the legal
                capacity and authority to bind such business entity to the terms and conditions contained in this document. The term
                “you” shall refer to both the business entity and the individual accessing the Service on behalf of such business entity.
            </p>

            <h2>THESE TERMS CONTAIN AN ARBITRATION AGREEMENT AND CLASS ACTION WAIVER.</h2>
            <p>PLEASE READ THEM CAREFULLY, SINCE THEY AFFECT YOUR LEGAL RIGHTS.</p>

            <h2>Acceptance of Terms</h2>
            <p>By creating an Emblem account, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>

            <h2>Use of Service</h2>
            <p>Users must be at least 13 years of age. If you are under 18, you confirm that you have parental or guardian consent to use the service.</p>

            <h2>Collection of Emblems</h2>
            <p>Emblems can be collected by proving the user's location through GPS.</p>
            <p>Each Emblem is designed to represent cultural, historical, or artistic significance.</p>
            <p>
                Emblems are digital souvenirs that users can collect, store, gift, and showcase. They are designed to represent a life experience
                for the collector as a keepsake, showcasing culture, history, story, identity of a territory and an entity.
            </p>

            <h2>Definition of 'Territory'</h2>
            <p>
                For the purposes of our service, the term 'territory' refers to any geographic area, regardless of its status as a recognized
                country by the United Nations or other international bodies. This includes, but is not limited to, nations, states, regions,
                provinces, territories, and any other type of geographic area, recognized or otherwise.
            </p>
            <p>
                Emblem uses the term 'territory' to inclusively cover all geographic areas where users may collect, store, gift, and showcase digital souvenirs, known as 'Emblems.' This allows us to encompass a broad range of locations, acknowledging the diversity and complexity of global geography and political status.
            </p>
            <p>
                Our use of the term does not imply any political stance or recognition by Emblem regarding the legal or political status of any territory.
            </p>
            <p>
                Our use of 'territory' aligns with our goal to provide a global service that transcends political and geographical boundaries, offering a platform for cultural exchange and discovery.
            </p>
            <p>
                We approach the concept of territory with respect and sensitivity, understanding that the political status of certain areas can be a subject of international debate and contention.
            </p>
            <h2>Emblem as a Digital World Expo</h2>
            <p>
                Emblem is akin to a digital version of a world expo. Just like a traditional world expo showcases pavilions from different countries, Emblem allows various territories, brands, and institutions to create their own digital profiles, akin to virtual pavilions.
            </p>
            <h2>Nature of Emblems</h2>
            <p>
                It is important to note that Emblems are not Non-Fungible Tokens (NFTs). They do not utilize blockchain technology and are not meant for cryptocurrency-related trading or speculative purposes.

                Emblem’s digital souvenirs are designed to be personal keepsakes or collectibles, symbolizing experiences, memories, or affiliations with places, events, or brands.
            </p>
            <h2>Global Inclusivity</h2>
            <p>
                Emblem's approach is globally inclusive, allowing diverse territories and entities to participate, thereby creating a rich tapestry of global cultures and stories.
            </p>
            <h2>Deployment of Emblems by Entities</h2>
            <p>
                Entities, including brands, institutions, and representatives of territories, are permitted to deploy Emblems at their legal establishments and during their events at various locations.
                Entities representing territories have the additional privilege of deploying Emblems in public areas within their respective territories.
                Entities must ensure that their deployment of Emblems complies with all local laws and regulations, including but not limited to, those pertaining to digital content, public spaces, and intellectual property.
            </p>

            <h2>Linking Emblems to Experiences</h2>
            <p>
                Emblems can be linked to a wide range of experiences, including but not limited to, physical events, cultural showcases, and historical commemorations.
                Additionally, Emblems can be linked to experiences that occur in the virtual world, providing an innovative way to bridge digital and physical realities.
            </p>
            <h2>Authority of Emblem in Deployment</h2>
            <p>
                Emblem, as a company, reserves the right to allow or disallow the deployment of Emblems in any area, based on our discretion and in compliance with legal standards and community guidelines.
                Emblem may intervene or advise on Emblem deployments to ensure they align with our platform's goals, values, and the overall user experience.
            </p>
            <h2>Responsibility and Compliance</h2>
            <p>
                Entities deploying Emblems are responsible for ensuring their content is appropriate, respectful, and non-infringing on the rights of others.
            </p>
            <h2>Intellectual Property Rights</h2>
            <p>
                Emblems created by institutions or brands are the intellectual property of those entities.
                Users do not own the Emblems they collect. Collection of an Emblem does not transfer any intellectual property rights to the user.
                Emblems are non-tradeable and are for personal collection and enjoyment only.
            </p>
            <h2>Platform Abuse:</h2>
            <p>
                Emblem reserves the right to destroy or “burn” Emblems issued in violation of these Terms, Emblem Privacy Policy, or any other applicable laws. Emblem reserves the right to ban violating users from the platform.</p>

            <h2>User Conduct</h2>
            <p>
                Users must not use Emblem for any illegal or unauthorized purpose.
                Harassment, abuse, or harm to another person or entity is strictly prohibited.
                Emblem reserves the right to monitor user activity for compliance with these terms.

            </p>

            <h2>Prohibited Activities</h2>
            <p>
                Collecting Emblems fraudulently, such as by manipulating your location data, is strictly prohibited.
                Selling or transferring your account or any Emblem outside the Emblem platform is not allowed.
            </p>

            <h2>Consequences of Violation</h2>
            <p>
                Violation of these terms may result in temporary or permanent suspension of your Emblem account.
                Emblem reserves the right to remove any Emblem collected fraudulently.
            </p>
            <h2>Privacy</h2>
            <p>
                Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and share your information.
            </p>

            <h2>Changes to Terms</h2>
            <p>
                Emblem reserves the right to modify these terms at any time. You are responsible for regularly reviewing these terms.
            </p>

            <h2>Disclaimer of Warranties</h2>
            <p>Emblem is provided "as is" without any warranties of any kind, either express or implied.</p>

            <h2>Limitation of Liability</h2>
            <p>
                Emblem shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any
                loss of profits or revenues.
            </p>

            <h2>Governing Law</h2>
            <p>These terms are governed by and construed in accordance with the laws of the jurisdiction where Emblem is headquartered.</p>

            <h2>By using Emblem, you acknowledge that you have read these Terms and Conditions and agree to be bound by them.</h2>
        </div>
    );
};

export default TermsAndConditions;
