import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import PrivacyPolicy from "./component/sections/legal/privacyPolicy";
import TermsAndConditions from "./component/sections/legal/termsConditions";
import './App.css';

export default function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<HomePage/>}></Route>
                    <Route path="/home" element={<HomePage/>}></Route>
                    <Route path="/privacy" element={<PrivacyPolicy/>}></Route>
                    <Route path="/terms" element={<TermsAndConditions/>}></Route>
                    <Route path="/cookies" element={<PrivacyPolicy/>}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}