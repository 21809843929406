// privacyPolicy.js

import React from "react";
import "./privacyPolicy.css"; // Create a CSS file for styling, adjust the path as needed

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1 className="section-title">Privacy Policy</h1>

      <div className="privacy-content">
        <p>
          <strong>Last Updated:</strong> 11th December, 2023
        </p>

        <p>
          Welcome to Emblem! Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Emblem application.
        </p>

        <h2>Information Collection</h2>

        <p>
          <strong>Personal Information:</strong> We collect personal information that you voluntarily provide to us when registering for the Emblem service. This may include your name, email address, and other contact information.
        </p>

        <p>
          <strong>Location Data:</strong> To collect Emblems, we require access to your device's location services. We use this data solely to verify your presence at a specific geographic location linked to an Emblem.
        </p>

        <h2>Use of Information</h2>

        <p>
          The information we collect is used to operate and maintain our service, improve user experience, communicate with you, and enforce our terms and conditions.
        </p>

        <h2>Sharing of Information</h2>

        <p>
          We do not sell, rent, or lease your personal information to third parties. Your information may be shared with third-party service providers to perform tasks on our behalf, such as hosting services or customer support, under confidentiality agreements.
        </p>

        <h2>Data Security</h2>

        <p>
          We implement reasonable and appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information and data stored on our site.
        </p>

        <h2>User Rights</h2>

        <p>
          You have the right to access, update, or request deletion of your personal information. You may decline to share certain personal information with us, but this may limit your ability to use certain features of our service.
        </p>

        <h2>Children's Privacy</h2>

        <p>
          Emblem does not knowingly collect or solicit information from anyone under the age of 13. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible.
        </p>

        <h2>Changes to Our Privacy Policy</h2>

        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions about this Privacy Policy, please contact us at [Insert Contact Information].
        </p>

        <p>
          By using Emblem, you consent to the collection and use of information in accordance with this policy.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
