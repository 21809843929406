import React from "react";
import "./emblemIntro.css";

function SectionWithImage() {
  const handleSignUpClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="background-image">
      <div className="containers">
        <div className="container1">
          <h1>The world's digital passport souvenir app</h1>
          <h2>
            Evolving the way people explore and remember
          </h2>
        </div>
        <div className="container2">

          <div className="sign-up-Intro">
            <button onClick={handleSignUpClick}>Join</button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default SectionWithImage;
