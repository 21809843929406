// src/components/NavBar.js

// import React, { useState } from "react";
import "./navBar.css";
import emblemLogo from "../../../assets/emblemWhiteLogo.png"; // Adjust the path as needed

function NavBar() {
  // const [isSignUpOpen, setIsSignUpOpen] = useState(false);

  const handleSignUpClick = () => {
    // setIsSignUpOpen(!isSignUpOpen);

    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth" // You can change this to "auto" for instant scrolling
    });
  };

  const handleSignUpClickAboutUs = () => {
    const aboutUsSection = document.getElementById("aboutUs");
    if (aboutUsSection) {
      aboutUsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSignUpClickTestimonials = () => {
    const testimonialsSection = document.getElementById("testimonials");
    if (testimonialsSection) {
      testimonialsSection.scrollIntoView({ behavior: "smooth" });
    }
  };


  return (
    <div className="nav-bar">
      <div className="logo">
        <img src={emblemLogo} alt="Your Logo" />
      </div>
      <div className="center-buttons">
        <button className="nav-button" onClick={handleSignUpClickAboutUs}>About us</button>
        <button className="nav-button" onClick={handleSignUpClickTestimonials}>Testimonials</button>
        <button className="nav-button" onClick={handleSignUpClick}>Contact</button>
      </div>
      <div className="sign-up">
        <button className="nav-button" onClick={handleSignUpClick}>Join</button>
      </div>
    </div>
  );
}

export default NavBar;