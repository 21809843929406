import React from "react";
import "./testimonials.css"; // Make sure to create the appropriate CSS file for styling
import linkedIn from '../../../assets/linkedin.png';
import paul from '../../../assets/paulSchafer.jpeg';
import pdf from '../../../assets/PaulSchaferEmblemLetter.pdf'; // Make sure to include the file extension

function Testimonials() {
    const handleDownload = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = pdf;
        link.target = '_blank'; // Open in a new tab if needed
        link.download = 'PaulSchaferEmblemLetter.pdf'; // Specify the desired file name

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };

    return (
        <div className="testimonials-container" id="testimonials">
            <h1 className="Testimonials-title">Testimonials</h1>

            <div className="testimonial">
                <img src={paul} alt="Author" className="author-picture" />

                <div className="testimonial-author">
                    <div className="author-info">
                        <h2 className="author-name">Paul Schafer</h2>
                        <p className="author-role">Founder and Director, World Culture Project</p>
                        <a href="https://www.linkedin.com/in/paul-schafer-83204527/" target="_blank" rel="noopener noreferrer">
                            <img src={linkedIn} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                        <a  href="http://worldcultureproject.org" target="_blank" rel="noopener noreferrer">Learn more about the World Culture Project</a>

                    </div>
                    <div className="testimonial-text">
                        <p>
                            "I was immediately struck by the incredible potential of this project.
                            Not only is it much needed at this time when more and more people
                            are traveling to different parts of the world to experience a variety of
                            cultures and array of fulfilling events, but also the opportunities for expansion of
                            this project in public, private, and commercial terms are enormous."
                        </p>
                        <a href="#" onClick={handleDownload}>Read More</a>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
