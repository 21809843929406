import NavBar from './component/sections/navigationBar/navBar';
import SectionWithImage from './component/sections/emblemIntro/emblemIntro';
import Footer from './component/sections/footer/Footer';
import EmailSection from './component/sections/joinSection/Join';
import EmblemDefintion from './component/sections/emblemDefinition/emblemDefinition';
import EmblemSteps from './component/sections/emblemSteps/EmblemSteps';
import AboutUs from './component/sections/aboutUs/AboutUs';
import Testimonials from './component/sections/testimonials/testimonials';

function HomePage(){
  return (
    <div className='HomePage'>
      <NavBar />
      <SectionWithImage/>
      <EmblemDefintion/>
      <EmblemSteps/>
      <AboutUs/>
      <Testimonials/>
      <EmailSection />
      <Footer />
    </div>
  )
}

export default HomePage;
