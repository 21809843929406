// Import React and the CSS file at the top of your component file
import React from "react";
import "./EmblemSteps.css";

import mapWithPins from '../../../assets/mapWithPins.jpeg';
import mapAndEmblem from '../../../assets/mapPhone.png';
import phone from '../../../assets/profilePhone.png';
import place from '../../../assets/placeArmes.png';



const EmblemSteps = () => {
    return (
        <div className="emblem-steps">
            <div className="section3">
                <div className="step1">
                    <div className="pic">
                    <img src={place} alt="Emblems" />
                    <img src={mapAndEmblem} alt="Emblems" />

                    </div>
                    <div className="text">
                        <h1>1.</h1>
                        <h2>Create an emblem for your brand/institution to feature in Emblem’s Map</h2>
                    </div>
                </div>
                <div className="step2">
                <div className="pic">
                <img src={mapWithPins} alt="Emblems" />

                    </div>
                    <div className="text">
                        <h1>2.</h1>
                        <h2>Collectors can only collect emblems by proving their presence/engagement through GPS and QR code</h2>
                    </div>
                </div>
                <div className="step3">
                <div className="pic">
                <img src={phone} alt="Emblems" />

                    </div>
                    <div className="text">
                        <h1>3.</h1>
                        <h2>Every emblem collected is showcased on the collector's profile, turning them into an ambassador for your brand</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmblemSteps;
