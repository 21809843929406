// EmailSection.js

import Axios from 'axios';
import React, { useState } from 'react';
import "./join.css";
const sendEmailURL = 'https://us-central1-emblem-c533b.cloudfunctions.net/sendEmail';

function EmailSection() {
  const [state, setState] = useState({
    email: '',
    name: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const { email, name } = state;

  const handleState = ({ target: { id, value } }) =>
    setState({ ...state, [id]: value });

    const sendEmail = (e) => {
        e.preventDefault();
      
        // Check if the name and email fields are empty
        if (!name.trim() || !email.trim()) {
          // If either field is empty, do not proceed with sending the email
          return;
        }
      
        const fixedSubject = 'Welcome to Emblem'; // Fixed subject
    const fixedMessage = `
      
      \n
      Thank you for joining Emblem, the innovative platform where art, culture, and technology converge.\n
      \n
      We're excited to share how each of you can be a part of this journey, whether as artists, users, or brand partners.\n
      \n
      **For Artists:** Your creativity is the heart of Emblem. We invite you to share more of your details so you can join our list of featured recommended artists and showcase your work to a global audience.\n
      \n
      **For Users:** Prepare to explore the world like never before. Our app launch is on the horizon, offering you the chance to collect unique digital Emblems from anywhere worldwide. Stay tuned for updates and be ready to become a cultural ambassador through Emblem.\n
      \n
      **For Brands/Institutions:** Feature your narrative through your own emblems. We offer a revolutionary way for you to connect with clients and advertise like never before. Transform your events, experiences, and products into emblems that are cherished and shared worldwide.\n
      \n
      We're thrilled to have you on board and can't wait to see the diverse ways in which you'll engage with Emblem.\n
      \n
      For more details, answer to this email, and let's embark on this journey together.\n
      \n
      Best regards,\n
      The Emblem Team
    `; 


      
        // Update state to indicate that the form has been submitted
        setSubmitted(true);
      
        Axios.get(sendEmailURL, {
          params: {
            subject: fixedSubject,
            message: fixedMessage,
            name,
            email,
          },
        });
      };
      

  return (
    <div className={`join ${submitted ? 'submitted' : ''}`}>
      <h1>Join</h1>
      <form
        onSubmit={sendEmail}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="input-group">
          <label htmlFor={'name'}>Name</label>
          <input id={'name'} type="Name" onChange={handleState} value={name} />
        </div>

        <div className="input-group">
          <label htmlFor={'email'}>Email</label>
          <input id={'email'} type="email" onChange={handleState} value={email} />
        </div>

        <input type={'submit'} value={submitted ? 'Submitted' : 'Send'} />
      </form>
    </div>
  );
}

export default EmailSection;
