import React from "react";
import "./AboutUs.css"; // Make sure to create the appropriate CSS file for styling
import danielPic from '../../../assets/daniel.png';
import miguelPic from '../../../assets/miguel.png';
import linkedIn from '../../../assets/linkedin.png';

function AboutUs() {
    return (
        <div className="about-us-container" id="aboutUs">
            <h1 className="About-title">About Us</h1>
            <div className="members">
                <div className="team-member">
                    <div className="member-info">
                        <img src={danielPic} alt="Member 1" className="member-picture" />
                        
                    </div>
                    <div className="info">
                    <h2 className="member-name">Daniel Michels</h2>
                        <p className="member-role">CEO and Founder</p>
                        <a href="https://www.linkedin.com/in/daniel-michels-567363202/" target="_blank" rel="noopener noreferrer">
                            <img src={linkedIn} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                        
                    </div>
                </div>

                <div className="team-member">
                    <div className="member-info">
                        <img src={miguelPic} alt="Member 2" className="member-picture" />
                        
                    </div>
                    <div className="info">
                    <h2 className="member-name">Miguel Curtido</h2>
                        <p className="member-role">CTO and Co-Founder</p>
                        <a href="https://www.linkedin.com/in/miguel-curtido-linares-9108b7253/" target="_blank" rel="noopener noreferrer">
                            <img src={linkedIn} alt="LinkedIn" className="linkedin-icon" />
                        </a>
                       
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AboutUs;
